import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'

import Layout from '../components/layout'
import Navigation from '../components/Navigation'
import style from './Events.module.css'

const Events = ({ data }) => {
  const events = [
    {
      title: 'Colours of MMA',
      date: '14th April 2019',
      venue: ''
    },
    {
      title: 'Right to Fight',
      date: '',
      venue: ''
    },
    {
      title: 'New Day',
      date: '',
      venue: ''
    },
    {
      title: 'Independence Mania',
      date: '',
      venue: ''
    },
  ]
  return (
    <Layout>
      <Navigation />
      <div className={style.container}>
        <ul className={style.eventList}>
          {events.map(event => (
            <li>
              <h2 className={style.name}>{event.title}</h2>
              <p className={style.date}> 
                {event.date ? <><span className={style.bold}>Date: </span> {event.date}</> : 'Date to be confirmed'}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  )
}

export default Events
